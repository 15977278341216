import moment from 'moment';
import Tootltip from '../../../../../components/BarData/Tootltip';
export function getChecklistName(checklistId: any, checklists: any[]) {
  const checklist = checklists.find((checklist) => Number(checklist.id) === Number(checklistId));
  return checklist ? checklist.name : '';
}
function InvoiceTemplateDSPNew({
  invoiceDetails,
  item,
  height,
  invoices,
}: {
  invoiceDetails: any;
  item: any;
  height?: any;
  invoices?: any;
}) {
  const amountStyles: React.CSSProperties = {
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const proofsArray =
    invoiceDetails?.proofs || invoiceDetails?.invoice?.additional_info?.proofs || [];

  const finalProofs = proofsArray?.filter((url: any) => url?.proof_type !== 'Video');
  const taxedAmount = () => {
    if (invoiceDetails?.invoice?.is_taxable && invoiceDetails?.invoice?.tax_percent) {
      const taxAmount =
        (invoiceDetails?.invoice?.tax_percent / 100) * invoiceDetails?.invoice?.price;
      return taxAmount.toFixed(3);
    } else {
      return 0;
    }
  };
  return (
    <div
      style={{
        height: `${height ? height : '25rem'}`,
        width: '100%',
        overflow: 'auto',
        paddingRight: 3,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          border: '0.1rem solid gray',
          borderRadius: '0.5rem',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
            borderBottom: '0.1rem solid gray',
            borderStyle: 'dotted',
          }}>
          <div>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              INVOICE
            </p>
            <p className='flex'>INVOICE NUMBER {invoiceDetails?.invoice?.number}</p>
          </div>
          <p className='flex' style={{ fontWeight: 'bold' }}>
            INVOICE DATE {moment(invoiceDetails?.invoice?.date).format('L')}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}>
          <div style={{ maxWidth: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              BILL TO
            </p>
            <Tootltip
              title={
                invoiceDetails?.invoice?.address ||
                'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920'
              }
              placement='bottom'>
              <p className=' text-ellipsis overflow-hidden max-w-[18rem] text-left'>
                {' '}
                {invoiceDetails?.invoice?.address ||
                  'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920'}
              </p>
            </Tootltip>
            <p className='flex'>Phone: {invoiceDetails?.invoice?.phone || '425-299-2247'}</p>
            <p className='flex'>
              Billing Email:{invoiceDetails?.invoice?.email || 'fleet@lmdmax.com'}
            </p>
          </div>
          <p style={{ fontWeight: 'bold' }}>
            Service Date {moment?.utc(invoiceDetails?.invoice?.service_date).format('L')}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
            width: '100%',
            gap: 5,
          }}>
          <div style={{ width: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              STATION DETAILS
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Customer Name
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.dsp?.name}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  {' '}
                  Short Code
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.dsp?.short_code}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Station Address
                </p>
                <Tootltip title={invoiceDetails?.dsp?.station_address || ''} placement='bottom'>
                  <p
                    className=' text-ellipsis overflow-hidden max-w-[100%] flex justify-start'
                    style={{ width: '60%' }}>
                    {' '}
                    {invoiceDetails?.station_address?.locationInfo || ''}
                  </p>
                </Tootltip>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Station Code
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.dsp?.station_code}
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <p className='flex' style={{ fontWeight: 'bold' }}>
              VEHICLE DETAILS
            </p>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Make
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.make}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Model
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.model}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Year
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.year}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  VIN
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.vin}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  License Plate
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.license_plate}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex' style={{ width: '40%' }}>
                  Van Name
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.van_name}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className='flex whitespace-nowrap text-[0.95rem]' style={{ width: '40%' }}>
                  Odometer Reading
                </p>
                <p className='flex' style={{ width: '60%' }}>
                  {invoiceDetails?.vehicle?.odometer_reading}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 2,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '2',
              backgroundColor: 'lightgray',
              marginLeft: 4,
              marginRight: 4,
            }}>
            <p
              style={{
                width: '15%',
                ...amountStyles,
              }}>
              Service Date
            </p>
            {/* description */}
            <p
              style={{
                width: '30%',
                ...amountStyles,
              }}>
              Service/Part Description
            </p>
            {/* product code */}
            <p
              style={{
                width: '10%',
                ...amountStyles,
                whiteSpace: 'nowrap',
              }}>
              Product Code
            </p>
            {/* qty */}
            <p
              style={{
                width: '10%',
                ...amountStyles,
              }}>
              Qty
            </p>
            {/* rate*/}
            <p
              style={{
                width: '10%',
                whiteSpace: 'nowrap',
                ...amountStyles,
              }}>
              Rate
            </p>
            {/* Amount */}
            <p
              style={{
                width: '10%',
                whiteSpace: 'nowrap',
                ...amountStyles,
              }}>
              Amount
            </p>
          </div>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                borderBottom: '0.1rem solid gray',
                borderStyle: 'dotted',
              }}>
              {/* service date */}
              <p
                style={{
                  width: '15%',
                  ...amountStyles,
                }}>
                {moment
                  ?.utc(invoiceDetails?.invoice?.service_date || item?.appointments?.[0]?.date)
                  .format('MM/DD/YYYY')}
              </p>
              {/* description */}
              <p
                className='flex flex-col'
                style={{
                  width: '30%',
                  ...amountStyles,
                }}>
                <p className='flex font-bold w-full capitalize'>
                  {' '}
                  {invoiceDetails?.invoice?.service_name}
                </p>
                <p className='flex w-full'>
                  <Tootltip title={invoiceDetails?.invoice?.description} placement={'bottom'}>
                    <p className='max-w-[13rem] text-ellipsis overflow-hidden'>
                      {invoiceDetails?.invoice?.description}
                    </p>
                  </Tootltip>
                </p>
              </p>
              {/* product code */}
              <p
                style={{
                  width: '10%',
                  ...amountStyles,
                  whiteSpace: 'nowrap',
                }}>
                {invoiceDetails?.invoice?.product_code}
              </p>
              {/* qty */}
              <p
                style={{
                  width: '10%',
                  ...amountStyles,
                }}>
                {invoiceDetails?.invoice?.quantity}
              </p>
              {/* rate*/}
              <p
                style={{
                  width: '10%',
                  whiteSpace: 'nowrap',
                  ...amountStyles,
                }}>
                $ {invoiceDetails?.invoice?.rate || invoiceDetails?.invoice?.price || 0}
              </p>
              {/* Amount */}
              <p
                style={{
                  width: '10%',
                  whiteSpace: 'nowrap',
                  ...amountStyles,
                }}>
                $ {Number(invoiceDetails?.invoice?.price).toFixed(2)}
              </p>
            </div>
          </>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Sub Total</p>
              <p>
                ${' '}
                {!isNaN(invoiceDetails?.invoice?.subtotal_amount)
                  ? `${invoiceDetails?.invoice?.subtotal_amount}`
                  : `${invoiceDetails?.invoice?.rate || invoiceDetails?.invoice?.price}`}{' '}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Tax ( {invoiceDetails?.invoice?.tax_percent || 0}%)</p>
              <p>$ {taxedAmount()}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2',
                marginLeft: 4,
                marginRight: 4,
                borderTop: '0.1rem solid gray',
                padding: 3,
                paddingLeft: 17,
                paddingRight: 20,
              }}>
              <p>Total Due</p>
              <p>
                ${' '}
                {!isNaN(invoiceDetails?.invoice?.subtotal_amount)
                  ? `${Number(invoiceDetails?.invoice?.total_amount).toFixed(2) || 0}`
                  : `${invoiceDetails?.invoice?.price}`}
              </p>
            </div>
          </>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '2',
              marginLeft: 4,
              marginRight: 4,
              border: '0.1rem solid gray',
            }}>
            <p
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
              }}>
              Signature
            </p>
            <p
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
              }}>
              Date
            </p>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: 'fit',
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}>
          <p className='flex' style={{ fontWeight: 'bold' }}>
            WorkProof Images
          </p>
          {finalProofs?.length > 0 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '0.1rem',
                  marginTop: '0.1rem',
                }}>
                {finalProofs?.map((url: any, index: number) => (
                  <>
                    <div
                      key={url?.file_url}
                      style={{
                        width: '12rem',
                        height: 'auto',
                        overflow: 'hidden',
                      }}>
                      <img
                        src={url?.file_url}
                        alt={`Proof ${index}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <p className='flex'>N/A</p>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
          <p className='flex' style={{ fontWeight: 'bold' }}>
            Note
          </p>
          <p className='flex'>{invoiceDetails?.invoice?.additional_info?.note || 'N/A'}</p>
        </div>
      </div>
    </div>
  );
}
export default InvoiceTemplateDSPNew;
