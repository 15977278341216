import { request } from './base';
import {
  IAddVehicle,
  ICheckAddVehicle,
  IGetLogs,
  IReassignPartnerBulk,
  IReassignVendor,
  IReassignVendorBulk,
  IRemovePartnerBulk,
  IRemoveTechBulk,
  IRemoveVendor,
  IRemoveVendorBulk,
} from './types';
export default class CsrAPI {
  static get(data: any) {
    return request({
      url: 'fleetmax_supervisor/v1/all_data',
      method: 'get',
      data: data,
    });
  }
  static getUnassignedCompanies(data: any) {
    return request({
      url: 'fleetmax_supervisor/v1/unassigned_companies',
      method: 'get',
      data: data,
    });
  }

  static getCsrCompanies(data: any) {
    return request({
      url: `fleetmax_supervisor/v1/all_companies${data.query}`,
      method: 'get',
      data: data,
    });
  }

  static assignCompany(data: any) {
    return request({
      url: '/fleetmax_supervisor/v1/fleetmax_csr',
      method: 'post',
      data: data,
    });
  }

  static add(data: any) {
    return request({
      url: 'users/v1/fleetmax_csr',
      method: 'post',
      data: data,
    });
  }

  static updateCSR(data: any) {
    return request({
      url: `users/v1/fleetmax_supervisor/update/id/${data.id}`,
      method: 'patch',
      data: data,
    });
  }

  static deleteCSR(data: any) {
    return request({
      url: `users/v1/fleetmax_supervisor/id/${data.id}?email=${data.email}&phone=${data.phone}`,
      method: 'patch',
    });
  }

  static removeCompany(data: any) {
    return request({
      url: `fleetmax_supervisor/v1/fleetmax_csr/id/${data.csr_id}/company/${data.company_id}`,
      method: 'patch',
      data: data,
    });
  }

  static getVendorInvoice(data: any) {
    return request({
      url: `pm-request/v1/preventive/invoice/${data.preventive_request_id}`,
      method: 'get',
      data: data,
    });
  }

  static generateDSPInvoice(data: any) {
    return request({
      url: 'csr/v1/dsp/generate_invoice',
      method: 'post',
      data: data,
    });
  }

  static sendDSPInvoice(data: any) {
    return request({
      url: 'csr/v2/dsp/send_invoice',
      method: 'patch',
      data: data,
    });
  }

  static generateVendorInvoice(data: any) {
    return request({
      url: 'csr/v1/vendor/generate_invoice',
      method: 'post',
      data: data,
    });
  }

  static sendVendorInvoice(data: any) {
    return request({
      url: 'csr/v2/vendor/send_invoice',
      method: 'patch',
      data: data,
    });
  }

  static getVehicleByVin(data: any) {
    return request({
      url: `fleetmax_supervisor/v1/get_vehicles_by_vin/${data.vin}`,
      method: 'get',
      data: data,
    });
  }

  static checkVehicleAdded(data: any) {
    return request({
      url: `csr/v1/check_vehicle_added/${data.vehicle}`,
      method: 'get',
      data: data,
    });
  }

  static getMileageInfo(data: any) {
    return request({
      url: `/mileage_log/v1/mileage_log/${data.v_id}/${data.date}?company_id=${data.company_id}`,
      method: 'get',
      data: data,
    });
  }

  static addVehicle(data: IAddVehicle) {
    return request({
      url: '/csr/v1/add_vehicle',
      method: 'post',
      data: data,
    });
  }

  static checkVehicle(data: ICheckAddVehicle) {
    return request({
      url: `/csr/v1/check_vehicle_added/${data.vehicle}`,
      method: 'get',
    });
  }

  static reAssignVendor(data: IReassignVendor) {
    return request({
      url: `/csr/v1/reassign_vendor`,
      method: 'patch',
      data: data,
    });
  }

  static reAssignVendorBulk(data: IReassignVendorBulk) {
    return request({
      url: `/csr/v1/reassign_vendor_bulk`,
      method: 'patch',
      data: data,
    });
  }

  static removeVendor(data: IRemoveVendor) {
    return request({
      url: `/csr/v1/remove_vendor`,
      method: 'patch',
      data: data,
    });
  }

  static removeVendorBulk(data: IRemoveVendorBulk) {
    return request({
      url: `/csr/v1/remove_vendor_bulk`,
      method: 'patch',
      data: data,
    });
  }
  static getPmLogs(data: IGetLogs) {
    return request({
      url: `/log_data/v1/data?preventive_request_id=${data.preventive_request_id}${
        data?.query || ''
      }`,
      method: 'get',
      data: data,
    });
  }
  static assignVendor(data: any) {
    return request({
      url: 'csr/v1/dsp_pending/assign_vendor',
      method: 'post',
      data: data,
    });
  }
  static assignVendorBulk(data: any) {
    return request({
      url: 'csr/v1/dsp_pending/assign_vendor_bulk',
      method: 'post',
      data: data,
    });
  }
  static editProof(data: any) {
    return request({
      url: `checklist_proof/v1/checklist_proof/${data.id}`,
      method: 'patch',
      data: data.payload,
    });
  }
  static editRequest(data: any) {
    return request({
      url: `csr/v1/edit_request`,
      method: 'patch',
      data: data?.payload,
    });
  }
  static removeVehicle(data: any) {
    return request({
      url: `csr/v1/remove_vehicle`,
      method: 'patch',
      data: data,
    });
  }
  static getTemplates() {
    return request({
      url: `fleetmax_templates/v1/fetch_template`,
      method: 'get',
    });
  }
  static createTemplate(data: any) {
    return request({
      url: 'fleetmax_templates/v1/create_template',
      method: 'post',
      data: data,
    });
  }
  static updateTemplate(data: any) {
    return request({
      url: `fleetmax_templates/v1/update_template/${data.id}`,
      method: 'patch',
      data: data.payload,
    });
  }
  static deleteTemplate(data: any) {
    return request({
      url: `fleetmax_templates/v1/delete_template/${data.id}`,
      method: 'delete',
    });
  }
  static moveToComplete(data: any) {
    return request({
      url: `csr/v1/move_to_complete`,
      method: 'patch',
      data: data,
    });
  }
  static moveToConfirmed(data: any) {
    return request({
      url: `/csr/v1/payment_processed`,
      method: 'patch',
      data: data,
    });
  }
  static getStationCOdes() {
    return request({
      url: `/csr/v1/station_codes`,
      method: 'get',
    });
  }
  static getServicesInvoice(data: any) {
    return request({
      url: `/csr/v1/invoice_details/${data?.id}`,
      method: 'get',
    });
  }
  static downloadInvoice(data: any) {
    return request({
      url: `/csr/v1/download_invoice?ids_arr=${JSON.stringify([data?.id])}`,
      method: 'get',
    });
  }
  static addTax(data: { tax_percent: string | null; id: string }) {
    return request({
      url: `/csr/v1/update_company/${data?.id}`,
      method: 'patch',
      data: {
        tax_percent: data.tax_percent,
      },
    });
  }
  static getAllInvoices(data: any) {
    return request({
      url: `/csr/v1/invoice_details?request_ids=[${data?.id}]`,
      method: 'get',
    });
  }

  static getRtsInspectionSummary(data: { vehicle_id: string }) {
    return request({
      url: `/inspections/v1/get_inspection_by_vehicle/${data.vehicle_id}`,
      method: 'get',
    });
  }

  static getSelectedInspectionSummary(data: { vehicle_id: string; preventive_request_id: string }) {
    return request({
      url: `/inspections/v1/get_selected_questions/${data.preventive_request_id}?vehicle=${data.vehicle_id}`,
      method: 'get',
    });
  }
  static unassignPartner(data: IRemovePartnerBulk) {
    return request({
      url: `/csr/v1/unassign_partner`,
      method: 'patch',
      data: data,
    });
  }
  static reassignPartner(data: IReassignPartnerBulk) {
    return request({
      url: `/csr/v1/reassign_partner`,
      method: 'patch',
      data: data,
    });
  }
  static unassignTech(data: IRemoveTechBulk) {
    return request({
      url: `/csr/v1/unassign_technician`,
      method: 'patch',
      data: data,
    });
  }
  static reassignTech(data: any) {
    return request({
      url: `/csr/v1/reassign_technician`,
      method: 'patch',
      data: data,
    });
  }
  static generateVendorInvoiceForDispute(data: any) {
    return request({
      url: '/csr/v1/vendor/update_invoice',
      method: 'post',
      data: data,
    });
  }

  static checkIfReadyToMove(parms: string) {
    return request({
      url: `/csr/v1/ready_to_move?${parms}`,
      method: 'get',
    });
  }
  static getRemarks(data: any) {
    return request({
      url: `/csr/v1/get_remarks?preventive_request_id=${data?.id || ''}`,
      method: 'get',
      data: data,
    });
  }
  static getService() {
    return request({
      url: `/csr/v1/services`,
      method: 'get',
    });
  }
  static addServiceImageName(data: any) {
    return request({
      url: '/csr/v1/services',
      method: 'post',
      data: data,
    });
  }
  static deleteServiceImageName(data: any) {
    return request({
      url: `/csr/v1/services/${data.id}`,
      method: 'delete',
    });
  }
  static getTooltipData(data: any) {
    return request({
      url: `/csr/v1/show_vehicles/${data.id}`,
      method: 'get',
    });
  }
  static changePriority(data: any) {
    return request({
      url: `/csr/v1/edit_priority`,
      method: 'patch',
      data: data,
    });
  }
  static updateDSPInvoice(data: any) {
    return request({
      url: `/csr/v1/dsp/update_invoice`,
      method: 'post',
      data: data,
    });
  }

  static getComparisonRequestReport(data: any) {
    return request({
      url: `/pm-request/v1/comparison_report?${data.params}`,
      method: 'get',
      data: data,
    });
  }
}
