export enum ActionType {
  SET_USER = 'SET_USER',
  DELETE_USER = 'DELETE_USER',
  USER_LOADER = 'USER_LOADER',
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CSR_CLIENTS = 'SET_CSR_CLIENTS',
  SET_VENDOR_TECHNICIAN = 'SET_VENDOR_TECHNICIAN',
  SET_VIEW_SCREEN = 'SET_VIEW_SCREEN',
  SET_CSR_DATA = 'SET_CSR_DATA',
  SET_CSR_DATA_INFO = 'SET_CSR_DATA_INFO',
  SET_VENDOR_DATA = 'SET_VENDOR_DATA',
  SET_VENDOR_ASSIGN_TECHNICIAN_DATA = 'SET_VENDOR_ASSIGN_TECHNICIAN_DATA',
  SET_DSP_ASSIGN_VENDOR_DATA = 'SET_DSP_ASSIGN_VENDOR_DATA',
  SET_BULK_VEHICLES = 'SET_BULK_VEHICLES',
  SET_ADD_VENDOR_PAGE_DATA = 'SET_ADD_VENDOR_PAGE_DATA',
  SET_REDIRECT_REQUEST = 'SET_REDIRECT_REQUEST',
  SET_ADD_SERVICE_BULK = 'SET_ADD_SERVICE_BULK',
  SET_VENDOR_DATA_INFO = 'SET_VENDOR_DATA_INFO',
  SET_SERVICES_INFO = 'SET_SERVICES_INFO',
  SET_CHAT_HEADS = 'SET_CHAT_HEADS',
  SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES',
  SET_SELECTED_CHAT = 'SET_SELECTED_CHAT',
  SET_CHATLIST_TYPE = 'SET_CHATLIST_TYPE',
  SET_CHATLIST_USERS = 'SET_CHATLIST_USERS',
  SET_CHATLIST_USERS_VENDOR = 'SET_CHATLIST_USERS_VENDOR',
  SET_CATAGORY_CHAT = 'SET_CATAGORY_CHAT',
  CHAT_LOADER = 'CHAT_LOADER',
  SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT',
  SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS',
  SET_APPOINTMENT_TIME = 'SET_APPOINTMENT_TIME',
  SET_SOCKET_INSTANCE = 'SET_SOCKET_INSTANCE',
  FETCH_DATA = 'FETCH_DATA',
  SET_COMPLETE_DATA = 'SET_COMPLETE_DATA',
  SET_GROUP_USERS_DETAILS = 'SET_GROUP_USERS_DETAILS',
  SET_BULK_REQUEST_DETAILS = 'SET_BULK_REQUEST_DETAILS',
  SET_SELECTED_QUESTIONNAIRE = 'SET_SELECTED_QUESTIONNAIRE',
  SET_INSPECION_DATA = 'SET_INSPECION_DATA',
  SET_INSPECION_DATA_VENDOR = 'SET_INSPECION_DATA_VENDOR',
  SET_VEHICLE_HISTORY = 'SET_VEHICLE_HISTORY',
  SET_NEW_QUESTION = 'SET_NEW_QUESTION',
  SET_INSPECT_REFETCH = 'SET_INSPECT_REFETCH',
  SET_CURRENT_SORT_TYPE = 'SET_CURRENT_SORT_TYPE',
  SET_LAYOUT_CONTENTS = 'SET_LAYOUT_CONTENTS',
  SET_MOVE_TO_CONFIRMED = 'SET_MOVE_TO_CONFIRMED',
  SET_ASSIGN_TECH_BULK = 'SET_ASSIGN_TECH_BULK',
  SET_SERVICES_LIST = 'SET_SERVICES_LIST',
  SET_VENDOR_DETAILS = 'SET_VENDOR_DETAILS',
  SET_DISPUTE = 'SET_DISPUTE',
  SET_ATTACHMENTS = 'SET_ATTACHMENTS',
  SET_SINGLE_SCHEDULE_VEHICLE = 'SET_SINGLE_SCHEDULE_VEHICLE',
  SET_SELECTED_ASSIGN_VENDOR = 'SET_SELECTED_ASSIGN_VENDOR',
  SET_SELECTED_ASSIGN_TECH = 'SET_SELECTED_ASSIGN_TECH',
  SET_SELECTED_ASSIGN_PARTNER = 'SET_SELECTED_ASSIGN_PARTNER',
  SET_REQUEST_MODAL_DATA = 'SET_REQUEST_MODAL_DATA',
  SET_MULTI_SELECT_REQ = 'SET_MULTI_SELECT_REQ',
  SET_MULTI_SELECT_INVOICE_ITEMS = 'SET_MULTI_SELECT_INVOICE_ITEMS',
  SET_BULK_REQUEST_DATA = 'SET_BULK_REQUEST_DATA',
  SET_PARTNER = 'SET_PARTNER',
  DELETE_PARTNER = 'DELETE_PARTNER',
  SET_SELECTED_DSP_SLOT = 'SET_SELECTED_DSP_SLOT',
  SET_ACCEPTED_DATA_VENDOR = 'SET_ACCEPTED_DATA_VENDOR',
  CHAT_HEADS_LOADER = 'CHAT_HEADS_LOADER',
  SET_DOWNLOAD_VIEW = 'SET_DOWNLOAD_VIEW',
  SET_INVOICE_VIEW = 'SET_INVOICE_VIEW',
  SET_CUSTOMIZE_VIEW = 'SET_CUSTOMIZE_VIEW',
  SET_USER_INFO_INVOICE = 'SET_USER_INFO_INVOICE',
  SET_ALERTS_OFFSET = 'SET_ALERTS_OFFSET',
  SET_FILTER_TYPE = 'SET_FILTER_TYPE',
  SET_PENDING_VEHICLES = 'SET_PENDING_VEHICLES',
  SET_REQUEST_LOADER = 'SET_REQUEST_LOADER',
  SET_ALL_BULK_VEHICLES = 'SET_ALL_BULK_VEHICLES',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_INVOICE_LOADER = 'SET_INVOICE_LOADER',
  SET_PARTNER_VENDORS = 'SET_PARTNER_VENDORS',
  SET_SHOW_MODEL = 'SET_SHOW_MODEL',
}
